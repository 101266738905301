<template>
    <svg width="20" height="20" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.63619 14.1667C5.4395 14.1667 6.09073 13.5078 6.09073 12.6951C6.09073 11.8825 5.4395 11.2236 4.63619 11.2236C3.83286 11.2236 3.18164 11.8825 3.18164 12.6951C3.18164 13.5078 3.83286 14.1667 4.63619 14.1667Z"
            stroke="#3A405A" stroke-width="1.5" stroke-linejoin="round" />
        <path
            d="M13.0001 14.1667C13.8034 14.1667 14.4547 13.5078 14.4547 12.6951C14.4547 11.8825 13.8034 11.2236 13.0001 11.2236C12.1968 11.2236 11.5456 11.8825 11.5456 12.6951C11.5456 13.5078 12.1968 14.1667 13.0001 14.1667Z"
            stroke="#3A405A" stroke-width="1.5" stroke-linejoin="round" />
        <path d="M3.06897 11.9613H1V0.833008H11V11.9613H6.5" stroke="#3A405A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        <path d="M11 11.9615V4.87988H14.4286L17 8.42071V11.9615H14.5245" stroke="#3A405A" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>