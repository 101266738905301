<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.3521 1.5C5.70759 1.5 6.03513 1.6928 6.20771 2.00363L7.40477 4.15994C7.56149 4.44227 7.56888 4.78376 7.42444 5.07261L6.27123 7.37908C6.27123 7.37908 6.60543 9.09725 8.00408 10.4959C9.40278 11.8946 11.1152 12.223 11.1152 12.223L13.4213 11.07C13.7103 10.9254 14.052 10.9329 14.3345 11.0899L16.4969 12.2922C16.8075 12.4649 17 12.7922 17 13.1475V15.63C17 16.8943 15.8257 17.8074 14.6279 17.4031C12.1676 16.573 8.34865 14.9924 5.92812 12.5719C3.50758 10.1513 1.92697 6.33239 1.09683 3.87217C0.69265 2.67429 1.60575 1.5 2.86996 1.5H5.3521Z"
      stroke="#3A405A"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>
